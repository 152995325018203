import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

import { ANNOUNCE_WINNER_DATE } from "@/assets/setting/event/setting"
import isOverTargetDate from "@/utils/isOverTargetDate"

const routes = setupLayouts(generatedRoutes)

routes.forEach((route) => {
    if (!route.children) return

    // 得獎名單頁面公布時間後才可進入，否則導回 Event 頁面
    if (route.children[0].name === 'EventAwards') {
        route.beforeEnter = (_to, _from, next) => {
            isOverTargetDate(ANNOUNCE_WINNER_DATE, 'second') ? next() : next({ name: 'Event' })
        }
    }
})

export default routes