import * as Sentry from '@sentry/vue'
import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query'
import { createApp } from 'vue'
import '@/main.scss'
import VueGtag from 'vue-gtag-next'

import App from '@/App.vue'
import routes from '@/plugins/router'
import store from '@/plugins/store/index'

const app = createApp(App)

const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
            }
        }
    }
}

app.use(routes)
    .use(store)
    .use(VueGtag, {
        property: {
            id: import.meta.env.VITE_APP_GA_CODE
        }
    })
    .use(VueQueryPlugin, vueQueryPluginOptions)
    .mount('#app')

Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router: routes }),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.MODE,
    sendDefaultPii: true
})
