import dayjs, { type OpUnitType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// 啟用套件
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 當前指定時區的日期 是否超過 指定時區的日期
 * @param targetDate ex: YYYY-MM-DD HH:MM:SS
 * @param compareUnit 比較時間單位(參考 dayjs)
 * @param timezone 時區，預設為`Asia/Taipei`
 * @returns Boolean
 * 
 * @see https://day.js.org/docs/en/manipulate/start-of#list-of-all-available-units
 */
export default function(
    targetDate: string,
    compareUnit: OpUnitType,
    timezone = 'Asia/Taipei',
) {
    const now = dayjs().tz(timezone)
    const targetDateTime = dayjs(targetDate).tz(timezone)

    return now.isAfter(targetDateTime, compareUnit)
}