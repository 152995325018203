import liff from '@line/liff'
import dayjs from 'dayjs'

import { useAuthStore } from '@/store/auth'

const id = import.meta.env.VITE_APP_LIFF_ID || ''
const envMode = import.meta.env.MODE
const LINE_FRIEND_LINK = import.meta.env.VITE_LINE_FRIEND_LINK || ''

export default async function liffInit() {
    // 檢查並清除過期的 ID Token
    const clearExpiredIdToken = (liffId: string) => {
        const keyPrefix = `LIFF_STORE:${liffId}:`
        const key = keyPrefix + 'decodedIDToken'
        const decodedIDTokenString = localStorage.getItem(key)

        if (!decodedIDTokenString) return
        const decodedIDToken = JSON.parse(decodedIDTokenString)
        if (dayjs().diff(dayjs.unix(decodedIDToken.exp)) > 0)
            localStorage.clear()
    }
    clearExpiredIdToken(id)

    const isInClient = await liff.isInClient()
    //TODO: 調整提示UX
    if (envMode === 'production' && !isInClient) {
        window.alert('請使用line應用程式開啟，將為您轉跳至磊山官網')
        window.location.href = 'https://www.leishan.com.tw/'
    } else
        await liff
            .init({
                liffId: id // Use own liffId
            })
            .then(async () => {
                // Start to use liff's api
                const authStore = useAuthStore()
                if (liff.isLoggedIn()) {
                    const friendShip = await liff.getFriendship()
                    const isFriend = friendShip.friendFlag
                    if (!isFriend) window.location.replace(LINE_FRIEND_LINK)

                    const profile = await liff.getProfile()
                    const token = await liff.getAccessToken()
                    const idToken = await liff.getIDToken()
                    const decodedIDToken = await liff.getDecodedIDToken()

                    if (decodedIDToken && decodedIDToken.exp) {
                        console.log(
                            `token expire at: ${dayjs
                                .unix(decodedIDToken.exp)
                                .format('YYYY-MM-DD HH:mm:ss')}`
                        )
                    }

                    authStore.$patch({
                        lineId: profile.userId,
                        displayName: profile.displayName,
                        token: token,
                        idToken: idToken,
                        tokenExpireAt: decodedIDToken?.exp
                    })
                } else {
                    liff.login()
                }
            })
            .catch((err) => {
                console.log(err)
            })
}
