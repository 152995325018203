import { defineStore } from 'pinia'

export interface AuthState {
    /**
     * line Id
     */
    lineId: string | null

    /**
     * 使用者名稱
     */
    displayName: string | null

    /**
     * line login access_token
     */
    token: string | null
    idToken: string | null
    tokenExpireAt: number | null
    bindCode: number | null
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => {
        return {
            lineId: null,
            displayName: null,
            token: null,
            idToken: null,
            tokenExpireAt: null,
            bindCode: 1
        }
    },
    actions: {
        isLogin() {
            return this.lineId && this.token
        }
    }
})
