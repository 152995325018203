


export const layouts = {
'DefaultLayout': () => import('/Users/tzuyuan/Desktop/leishan_ctw/src/layouts/DefaultLayout.vue'),
'ListLayout': () => import('/Users/tzuyuan/Desktop/leishan_ctw/src/layouts/ListLayout.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'DefaultLayout'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
