import liff from '@line/liff'
import { defineStore } from 'pinia'
import { sleep } from 'radash'
import { reactive, ref, Ref } from 'vue'

interface MemberInfo {
    customerName: string
    userId: string
    mobile: string
    lastSelfVerifyTime: string
    isConfirmInsuranceNewPolicy: boolean
    isSelfVerify: boolean
}

export const useAppStore = defineStore('app', () => {
    const memberInfo: Ref<MemberInfo> = ref({
        customerName: '',
        userId: '',
        mobile: '',
        lastSelfVerifyTime: '',
        isConfirmInsuranceNewPolicy: false,
        isSelfVerify: false
    })

    const messageState = reactive({
        isShow: false,
        msg: ''
    })

    const isShowLoading = ref(false)
    const loadingCount = ref(0)
    const errorModal = ref({
        isShow: false,
        title: '',
        content: '',
        titleImage: '',
        primaryWord: '',
        primaryCallback: undefined as (() => void) | undefined,
        closeCallback: undefined as (() => void) | undefined
    })

    function showLoading() {
        loadingCount.value++
        isShowLoading.value = true
    }

    async function hideLoading(timeout?: number, isScrollTop = true) {
        if (timeout) await sleep(timeout)
        loadingCount.value > 0 && loadingCount.value--
        if (isScrollTop) window.scrollTo({ top: 0 })
        if (!loadingCount.value) isShowLoading.value = false
    }

    interface ErrorModalParams {
        title?: string
        titleImage?: string
        content?: string
        primaryWord?: string
        primaryCallback?: () => void
        closeCallback?: () => void
    }

    function showErrorModal(data: ErrorModalParams) {
        errorModal.value = {
            isShow: true,
            title: data.title || '查詢時出現錯誤',
            titleImage: data.titleImage || '',
            content: data.content || '若持續出現此問題，請聯繫資訊客服',
            primaryWord: data.primaryWord || '',
            primaryCallback: data.primaryCallback || undefined,
            closeCallback: data.closeCallback || undefined
        }
    }

    function showApiErrorModal() {
        errorModal.value = {
            isShow: true,
            title: '查詢時出現錯誤',
            titleImage: '',
            content: '若持續出現此問題，請至幫助中心留言，將有專人為您服務',
            primaryWord: '',
            primaryCallback: undefined,
            closeCallback: () =>
                liff.openWindow({ url: import.meta.env.VITE_LINE_FRIEND_LINK })
        }
    }

    function hideErrorModal() {
        errorModal.value.isShow = false
    }

    return {
        memberInfo,
        messageState,
        isShowLoading,
        errorModal,
        showLoading,
        hideLoading,
        showErrorModal,
        hideErrorModal,
        showApiErrorModal
    }
})
