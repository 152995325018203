<script setup lang="ts">
import { computed } from 'vue'

import AtomicButton from '../atomics/AtomicButton.vue'
import Modal from './Modal.vue'

import { useAppStore } from '@/store/app'

const appStore = useAppStore()

const isShow = computed({
    get: () => appStore.errorModal.isShow,
    set: (val: boolean) => {
        appStore.$patch({ errorModal: { isShow: val } })
    }
})

function primaryHandler() {
    appStore.errorModal.primaryCallback && appStore.errorModal.primaryCallback()
    appStore.$patch({ errorModal: { primaryCallback: undefined } })
    isShow.value = false
}

function close() {
    appStore.errorModal.closeCallback && appStore.errorModal.closeCallback()
    appStore.$patch({ errorModal: { closeCallback: undefined } })
    isShow.value = false
}
</script>

<template>
    <Modal
        v-model="isShow"
        :title="appStore.errorModal.title"
        title-color="secondary"
        title-icon="notice-icon"
        :bgClickable="false"
    >
        <template #titleImage>
            <img
                v-if="appStore.errorModal.titleImage"
                :src="appStore.errorModal.titleImage"
                alt="error_image"
            />
            <img
                v-else
                src="@/assets/image/modal/error_modal.png"
                alt="error_image"
            />
        </template>
        <template #content>
            <div class="text-base">{{ appStore.errorModal.content }}</div>
        </template>
        <template #footer>
            <AtomicButton
                v-if="appStore.errorModal.primaryWord"
                class="h-11"
                :word="appStore.errorModal.primaryWord"
                theme="primary"
                font-weight="medium"
                rounded="full"
                @click="primaryHandler"
            />
            <AtomicButton
                class="h-11"
                word="關閉"
                theme="disabled"
                font-weight="medium"
                rounded="full"
                @click="close"
            />
        </template>
    </Modal>
</template>
