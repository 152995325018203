<script setup lang="ts">
import { ButtonHTMLAttributes, computed } from 'vue'

import type { RouteLocationRaw } from 'vue-router'

interface AtomicButtonProps {
    type?: ButtonHTMLAttributes['type']
    word?: string
    // Button style
    variant?: 'contained' | 'outlined'
    theme?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'error'
        | 'white'
        | 'disabled'
        | 'yellow'
        | 'transparent'
    size?: 'lg' | 'sm' | 'popup' | 'custom'
    rounded?: 'normal' | 'lg' | 'full' | '0'
    border?: 'none' | 'normal' | 'lg'
    shadow?: boolean

    // Text style
    fontWeight?: 'normal' | 'medium' | 'bold'
    fontSize?: 'xs' | 'sm' | 'lg' | 'base'

    disabled?: boolean

    // icon
    prependIcon?: string
    appendIcon?: string
    iconSize?: number
    stacked?: boolean

    // 若有傳則為 <router-link :to=to />
    to?: RouteLocationRaw
}

const props = withDefaults(defineProps<AtomicButtonProps>(), {
    type: 'button',
    variant: 'contained',
    theme: 'primary',
    size: 'lg',
    rounded: 'normal',
    border: 'normal',
    shadow: false,
    fontWeight: 'normal',
    fontSize: 'sm',
    disabled: false,
    prependIcon: '',
    appendIcon: '',
    iconSize: 40,
    stacked: false,
    to: undefined
})

const rootClass = computed(() => {
    const classList: string[] = []
    const BASIC_CLASS = 'atomic-button'
    const {
        size,
        variant,
        theme,
        rounded,
        disabled,
        border,
        shadow,
        fontWeight,
        fontSize,
        stacked
    } = props

    const themeClass = disabled
        ? `${BASIC_CLASS}--disabled`
        : `${BASIC_CLASS}--${theme}`

    classList.push(
        `${BASIC_CLASS}--${size}`,
        `${BASIC_CLASS}--rounded--${rounded}`,
        `${BASIC_CLASS}--border--${border}`,
        `${BASIC_CLASS}--${variant}`,
        `${BASIC_CLASS}--fontWeight--${fontWeight}`,
        `${BASIC_CLASS}--fontSize--${fontSize}`,
        themeClass
    )
    if (stacked) classList.push(`${BASIC_CLASS}--stacked`)
    if (shadow) classList.push(`${BASIC_CLASS}--shadow`)

    return classList
})

const sizeStyle = computed(() => {
    return { '--icon-size': `${props.iconSize}px` }
})

const rootComponent = computed(() => {
    return props.to ? 'router-link' : 'button'
})
</script>

<template>
    <component
        :is="rootComponent"
        :type="rootComponent === 'button' ? type : undefined"
        :to="to"
        class="atomic-button"
        :class="rootClass"
        :style="sizeStyle"
        :disabled="props.disabled"
    >
        <span v-if="$slots.prepend">
            <slot name="prepend" />
        </span>
        <div
            v-if="props.prependIcon"
            class="atomic-button--icon"
            :class="props.prependIcon"
        />
        <span>
            <slot name="default" />
            {{ props.word }}
        </span>
        <div
            v-if="props.appendIcon"
            class="atomic-button--icon"
            :class="props.appendIcon"
        />
        <span v-if="$slots.append">
            <slot name="append" />
        </span>
    </component>
</template>

<style scoped lang="scss">
$name: '.atomic-button';
$theme-list: (primary, secondary, success, error, disabled, white, yellow, transparent);

#{$name} {
    @apply inline-flex items-center justify-center;
    @apply border-solid border-transparent;
    @apply transition-colors;

    span {
        @apply select-none text-center leading-5;
    }

    /* size */
    &--lg {
        @apply w-full grow py-[10px];
    }

    &--sm {
        @apply px-12 py-2;
    }

    &--popup {
        @apply px-4 py-1;
    }

    /* rounded */
    &--rounded {
        &--normal {
            @apply rounded;
        }

        &--lg {
            @apply rounded-lg;
        }

        &--full {
            @apply rounded-full;
        }
    }

    /* font-weight */
    &--fontWeight {
        &--normal {
            @apply font-normal;
        }

        &--medium {
            @apply font-medium;
        }

        &--bold {
            @apply font-bold;
        }
    }

    /* font-size */
    &--fontSize {
        &--xs {
            @apply text-xs;
        }

        &--sm {
            @apply text-sm;
        }

        &--lg {
            @apply text-lg;
        }

        &--base {
            @apply text-base;
        }
    }

    /* border */
    &--border {
        &--none {
            @apply border-0;
        }
    
        &--normal {
            @apply border;
        }

        &--lg {
            @apply border-[2.5px];
        }
    }

    /* shadow */
    &--shadow {
        box-shadow: 0 4px 4px 0 rgb(0 0 0 / 0.1);
    }

    /* icon */
    &--icon {
        width: var(--icon-size);
        height: var(--icon-size);
    }

    &--stacked {
        @apply flex flex-col;
    }

    /* button style */
    &--contained {
        @apply text-white;

        @each $theme in $theme-list {
            &#{$name}--#{'' + $theme} {
                @apply bg-#{"" + $theme} active:bg-#{"" + $theme}-active;

                @if $theme == disabled {
                    @apply text-disabled-text;
                }

                @if $theme == white {
                    @apply text-black-secondary;
                }

                @if $theme == yellow {
                    @apply text-black-secondary;
                }
            }
        }
    }

    &--outlined {
        @each $theme in $theme-list {
            &#{$name}--#{'' + $theme} {
                @apply border-#{"" + $theme} text-#{"" + $theme};
            }
        }
    }
}
</style>
