<script setup lang="ts">
import { computed } from 'vue'

interface ModalProps {
    title?: string
    titleColor?: 'primary' | 'secondary' | 'success' | 'error' | 'black'
    titleIcon?: string
    titleIconSize?: number
    bgClickable?: boolean
    modelValue: boolean
}

interface ModalEmits {
    (e: 'update:modelValue', value: boolean): void
}

const props = withDefaults(defineProps<ModalProps>(), {
    bgClickable: true,
    titleColor: 'black',
    titleIconSize: 20
})
const emits = defineEmits<ModalEmits>()

const rootClass = computed(() => {
    const BASIC_CLASS = 'modal'
    const { titleColor } = props
    return [BASIC_CLASS, `${BASIC_CLASS}--title--${titleColor}`]
})

const titleIconClass = computed(() => {
    return [props.titleIcon, `title-icon`]
})
</script>

<template>
    <Transition name="fade">
        <div
            v-if="props.modelValue"
            class="modal fixed top-0 left-0 w-screen h-screen bg-[#00000050] center px-8 z-10"
            :class="rootClass"
            @click.self="bgClickable && emits('update:modelValue', false)"
        >
            <div
                ref="mainWrapper"
                class="wrapper grow bg-white overflow-hidden rounded-[30px] max-w-[311px]"
            >
                <!-- modal title & content -->

                <div
                    ref="contentWrapper"
                    class="main p-4 flex-column gap-4 min-h-[200px] center"
                >
                    <!-- modal title -->
                    <div
                        v-if="$slots.titleImage"
                        class="w-[100px] h-[100px] -mb-2"
                    >
                        <slot name="titleImage" />
                    </div>

                    <div
                        v-if="props.title || $slots.title"
                        :style="{ '--icon-size': `${props.titleIconSize}px` }"
                        class="title text-[18px] gap-2 font-medium center -mb-3"
                    >
                        <slot v-if="$slots.titleIcon" name="title-icon"></slot>
                        <div
                            v-if="props.titleIcon"
                            :class="titleIconClass"
                        ></div>
                        <slot name="title">{{ props.title }}</slot>
                    </div>

                    <!-- modal content -->
                    <div
                        class="content text-black-secondary text-base text-center"
                    >
                        <slot name="content" />
                    </div>

                    <div
                        v-if="$slots.footer"
                        class="w-full border-b border-disabled"
                    ></div>

                    <!-- modal footer -->
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped lang="scss">
$name: '.modal';
$theme-list: (primary, secondary, success, error, black);

#{$name} {
    .title-icon {
        width: var(--icon-size);
        height: var(--icon-size);
    }

    &--title {
        @each $theme in $theme-list {
            &--#{'' + $theme} {
                @apply text-#{"" + $theme};
            }
        }
    }
}
</style>
