import { trackRouter } from 'vue-gtag-next'
import { createRouter, createWebHistory } from 'vue-router'

import routes from './routesSetting'

import liffInit from '@/plugins/liff/index'
import { useAppStore } from '@/store/app'
import { useAuthStore } from '@/store/auth.ts'

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
    routes
})

export const noLoginRequiredPages: Array<string> = ['location']
export const needRegisteredPages: Array<string> = ['insurance']

//eslint-disable-next-line
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) document.title = to.meta.title as string

    const name: string | symbol = to?.name ?? ''
    const authStore = useAuthStore()
    const appStore = useAppStore()

    if (to.name === 'terms') {
        // 在 meta 內設定 from 來源頁面
        to.meta.from = from.name
    }

    if (!noLoginRequiredPages.includes(String(name)) && !authStore.isLogin()) {
        appStore.showLoading()
        await liffInit()
        appStore.hideLoading()
    }
    next()
})

router.afterEach((to, from) => {
    if (to.fullPath !== from.fullPath && !from.fullPath.includes('terms'))
        window.scrollTo({ top: 0, behavior: 'smooth' })
})

trackRouter(router)

export default router
