<script setup lang="ts">
import { onMounted } from 'vue'
import { RouterLink, useRouter } from 'vue-router'

const router = useRouter()

onMounted(() => !import.meta.env.DEV && router.push('/register'))
</script>

<template>
    <div class="flex-column pages">
        <RouterLink to="/liff-test">ＬＩＦＦ－ＩＮＦＯ</RouterLink>
        <RouterLink to="/insurance">我的保單</RouterLink>
        <RouterLink to="/insurance/new">新增保單</RouterLink>
        <RouterLink to="/location">磊山服務據點</RouterLink>
        <RouterLink to="/register">磊山會員綁定</RouterLink>
        <RouterLink to="/help">幫助中心</RouterLink>
        <a href="https://ctw.finext.app/businessCard/556">業務名片</a>
        <RouterLink to="/share/sales/56">分享業務名片</RouterLink>
        <RouterLink to="/download/claim/A123456789">理賠明細下載</RouterLink>
        <RouterLink to="/event">演唱會抽獎活動</RouterLink>
    </div>
</template>

<style scoped lang="scss">
.pages a {
    @apply bg-primary text-white mb-2 rounded-full p-2 text-center;
}
</style>
